import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { DialogRef } from '@app/dialog/models';

@Component({
  selector: 'app-promote-russian-version-dialog',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './promote-russian-version-dialog.component.html',
  styleUrls: ['./promote-russian-version-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PromoteRussianVersionDialogComponent {
  constructor(public dialogRef: DialogRef) {}
}
