<div class="notification" [ngClass]="typeCSSClass" (click)="close()">

  @switch (type) {
    @case (NotificationType.INFO) {
      <i class="notification__pictogram"></i>
    }
    @case (NotificationType.SUCCESS) {
      <i class="notification__pictogram teq-check"></i>
    }
    @case (NotificationType.ERROR) {
      <i class="notification__pictogram teq-close"></i>
    }
    @default {
      <i class="notification__pictogram"></i>
    }
  }
  <div class="notification__body">
    <p class="notification__message">{{ message | translate }}</p>
    <i class="notification__icon teq-close"></i>
  </div>
</div>