<div class="promote-russion-version-dialog">
  <h2>{{ 'RUSSIAN_SITE_PROMOTE_DIALOG_TITLE' | translate }}</h2>
  <p>{{ 'RUSSIAN_SITE_PROMOTE_DIALOG_DESCRIPTION' | translate }}</p>
  <div class="buttons">
    <button class="button button--small" (click)="dialogRef.close(true)">
      {{ 'RUSSIAN_SITE_PROMOTE_DIALOG_CONFIRM' | translate }}
    </button>
    <button class="button button--gray button--small" (click)="dialogRef.close()">
      {{ 'RUSSIAN_SITE_PROMOTE_DIALOG_CANCEL' | translate }}
    </button>
  </div>
</div>
